<template>
  <div class="home" id="top">
        <!-- <smooth-scrollbar> -->
  <main>
    <nav id="nav">
      <div class="wrapping-content">
<router-link to="" data-cursor-hover v-scroll-to="'#top'"><img class="logo" src="@/assets/logo_black.svg" alt="">
      </router-link>
      <!-- <p><router-link to="">Fady Elabed</router-link></p> -->
      <ul>
                <li><router-link to="/" data-cursor-hover>Back</router-link></li>
                <li><router-link to="" data-cursor-hover v-scroll-to="'#projects'">Projects</router-link></li>
        <li><router-link to="" data-cursor-hover v-scroll-to="'#personalwork'">Tryouts</router-link></li>
        <li><router-link to="" data-cursor-hover v-scroll-to="'#about'">Contact</router-link></li>

      </ul>
        </div>
    </nav>

    <section id="projects" class="portfolio-wrapper">
        <div class="wrapping-content">
          <div class="portfolio projects-2024">
              <h1>Client projects – 2024</h1>
            <div class="portfolio-item">
              <video class="" loop muted autoplay>
                <source src="@/assets/dilea.mp4" type="video/mp4">
                Your browser does not support the video tag.
              </video>            
      <p><span>Dilea</span> – Lactoseloze dagen campaign</p>
          </div>
            <div class="portfolio-item">
              <video class="" loop muted autoplay>
                <source src="@/assets/pauwels.mp4" type="video/mp4">
                Your browser does not support the video tag.
              </video>            
      <p><span>Pauwels</span> – 2024 'MMMMM' campaign</p>
          </div>
           <div class="portfolio-item">
              <video class="" loop muted autoplay>
                <source src="@/assets/ensor.mp4" type="video/mp4">
                Your browser does not support the video tag.
              </video>            
      <p><span>Stad Antwerpen</span> – James Ensor 'Staten van verbeelding' promotional video</p>
          </div>
                     <div class="portfolio-item">
              <video class="" loop muted autoplay>
                <source src="@/assets/kidibul.mp4" type="video/mp4">
                Your browser does not support the video tag.
              </video>            
      <p><span>Kidibul</span> – Animated keyvisual</p>
          </div>
          </div>
          <div class="portfolio">
              <h1>Client projects* – 2023</h1>
          <div class="portfolio-item">
            <router-link to="/brik" data-cursor-hover>
              <img class="active" src="@/assets/brikcombi.gif" id="portfolio-image">
            </router-link>
            <p><span>BRIK</span> – Studeren in Brussel campaign</p>
          </div>
                      <div class="portfolio-item">
                  <router-link to="/drip" data-cursor-hover>
                  <img class="active" src="@/assets/drip.jpg">
                  </router-link>
                <p>DRIP Festival</p>
            </div>
          <div class="portfolio-item">
                  <router-link to="/casper" data-cursor-hover>
                  <img class="active" src="@/assets/casper_1.gif">
                  </router-link>
                <p>CASPER - Choice never tasted so good</p>
            </div>

            <div class="portfolio-item">
                  <router-link to="/fodbosa" data-cursor-hover>
                  <img class="active" src="@/assets/fodbosa.png">
                  </router-link>
                <p>Federal Innovation Award</p>
            </div>
            <div class="portfolio-item">
                  <router-link to="/roast" data-cursor-hover>
                  <img class="active" src="@/assets/roast.jpg">
                  </router-link>
                <p>ROAST - Identity</p>
            </div>

            <div class="portfolio-item">
                  <router-link to="/lpq" data-cursor-hover>
                  <img class="active" src="@/assets/lpq.jpg">
                  </router-link>
                <p>Le Pain Quotidien</p>
            </div>
            <div class="portfolio-item">
                  <router-link to="/vlaamserand" data-cursor-hover>
                  <img class="active" src="@/assets/vlaamserand.jpg">
                  </router-link>
                <p>Vlaamse Rand Boomt</p>
            </div>
            <div class="portfolio-item">
                  <router-link to="/ww" data-cursor-hover>
                  <img class="active" src="@/assets/ww.png">
                  </router-link>
                <p>Wonderweekend</p>
            </div>
            <div class="portfolio-item">
                  <router-link to="/aiki" data-cursor-hover>
                  <img class="active" src="@/assets/aiki.jpg">
                  </router-link>
                <p>Fortnite x Aïki</p>
            </div>
            <div class="portfolio-item">
                  <router-link to="/recupel" data-cursor-hover>
                  <img class="active" src="@/assets/recupel.png">
                  </router-link>
                <p>Recupel</p>
            </div>
                        <div class="portfolio-item">
                  <router-link to="/indr" data-cursor-hover>
                  <img class="active" src="@/assets/indr.png">
                  </router-link>
                <p>Indiandribble</p>
            </div>
                        <p style="font-size: 0.7em; clear: both;">*These projects were finalised during my workperiod at Indiandribble. / © Concept & strategy developed by Indiandribble – All rights & copyright are reserved to Indiandribble.</p>       


        </div>
        <h1 id="personalwork">Passion projects ↦ <a data-cursor-hover href="https://www.instagram.com/elabedfady/" target="_blank" ><img class="insta active" src="@/assets/ig.svg"></a></h1>
        <section class="personalwork" >
          <div><a data-cursor-hover href="https://www.instagram.com/p/CnITC12jL43/" target="_blank" >       <video autoplay loop muted>
            <source src="@/assets/petals.mp4" type="video/mp4">
            Your browser does not support the video tag.
        </video></a></div>
        <div><a data-cursor-hover href="https://www.instagram.com/p/CncKzqhj8BP/" target="_blank" >       <video autoplay loop muted>
            <source src="@/assets/hoekpunten.mp4" type="video/mp4">
            Your browser does not support the video tag.
        </video></a></div>
                <div><a data-cursor-hover href="https://www.instagram.com/p/CnkSox-jqnB/" target="_blank" >       <video autoplay loop muted>
            <source src="@/assets/chromaticnoise.mp4" type="video/mp4">
            Your browser does not support the video tag.
        </video></a></div>
                <div><a data-cursor-hover href="https://www.instagram.com/p/CoP_SkPDofy/" target="_blank" >       <video autoplay loop muted>
            <source src="@/assets/flares.mp4" type="video/mp4">
            Your browser does not support the video tag.
        </video></a></div>
                <div><a data-cursor-hover href="https://www.instagram.com/p/CnuZPmuD0Wq/" target="_blank" >       <video autoplay loop muted>
            <source src="@/assets/clouds.mp4" type="video/mp4">
            Your browser does not support the video tag.
        </video></a></div>
                        <div><a data-cursor-hover href="https://www.instagram.com/p/CopueJoDlVa/" target="_blank" >       <video autoplay loop muted>
            <source src="@/assets/valentines.mp4" type="video/mp4">
            Your browser does not support the video tag.
        </video></a></div>
        <router-link class="btn-arrow" to="/archive" data-cursor-hover>View archived projects</router-link>
        </section>
        </div>

    </section>
    <section id="about" class="about">
    <img class="profielfoto hide" src="@/assets/portret.jpg" alt="">
    <article>
        <h2>Aangenaam, enchanté.</h2>
        <p>Nice to meet you, I’m Fady, a passionate creative based in Brussels. In my free time I like to go out for a run, practice/watch boxing, edit videos, play indie games and attend concerts. I'm fluent in Dutch, English and I also know French.</p>
        <p>What do I specialize in? <u>Visual identity branding</u>, <u>social media & advertising design</u>, <u>everything print (DTP)</u>, <u>front-end web design & development</u>, <u>motion design</u> and <u>video production/editing</u>.</p>
    <br>

    <p>Clients I've worked with:</p>
    <figure class="client-logos">
         <img src="@/assets/clients2.svg" alt="">
    </figure>
            <img class="profielfoto show" src="@/assets/portret.jpg" alt="">

       <!-- <div>
       <img src="@/assets/polestar.svg" alt="">
       </div>
      <div>
       <img src="@/assets/recupel.svg" alt="">
       </div>
            <div>
       <img src="@/assets/brusselsairport.svg" alt="">
       </div>
        <div>
       <img src="@/assets/alpro.svg" alt="">
       </div>
               <div>
       <img src="@/assets/lpq.svg" alt="">
       </div>
               <div>
       <img src="@/assets/brik.svg" alt="">
       </div>
               <div>
       <img src="@/assets/casper.svg" alt="">
       </div>
               <div>
       <img src="@/assets/payconiq.svg" alt="">
       </div>
               <div>
       <img src="@/assets/ww.svg" alt="">
       </div> -->
    </article>
    </section>
       <section id="contact" class="contact">
      <div class="wrapping-content">
        <a data-cursor-hover href="https://www.linkedin.com/in/fady-elabed-10603a183/" target="_blank">
        <svg version="1.1" id="linkedin" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
          viewBox="0 0 23.3 22.4" style="enable-background:new 0 0 23.3 22.4;" xml:space="preserve">
        <path d="M5.1,22.1h-5v-15h5V22.1z M2.6,5.1C1.3,5.1,0.1,4,0.1,2.6s1.1-2.5,2.5-2.5c1.4,0,2.5,1.1,2.5,2.5C5.1,4,4,5.1,2.6,5.1z
          M23.1,22.1h-4.8v-7.3c0-1.7,0-4-2.5-4c-2.5,0-2.9,1.9-2.9,3.9v7.4H8.1v-15h4.6v2.1h0.1C13.5,8,15,6.8,17.4,6.8
          c4.9,0,5.8,3.1,5.8,7.2C23.1,13.9,23.1,22.1,23.1,22.1z"/>
        </svg>
        </a>

        <h2 class="l-indent">Let's have a chat <span>^.^</span></h2>
        <p>Interested in my work? Or want to talk about something we have in common? Reach out for a conversation.</p>
        <article>
        <a href="mailto:fady.elabed@hotmail.com" data-cursor-hover>fady.elabed@hotmail.com</a>
        </article>
      </div>
                      <p class="copyright">&copy; Designed & developed by Fady Elabed</p>        
    </section>
  </main>
              <cursor-fx>
    </cursor-fx>

  </div>
</template>

<script>


import { gsap } from 'gsap'



export default {
  mounted: function () {
    this.startAnimations()
  },
  methods: {
    startAnimations: function () {
      gsap.from('h1', {
        duration: 0.8,
        opacity: 0,
        stagger: 0.2,
        delay: 1.5,
        y: -35,
        ease: 'power3.out'
      }),
      gsap.from('.portfolio div', {
        duration: 0.8,
        opacity: 0,
        stagger: 0.2,
        delay: 1.8,
        y: -35,
        ease: 'power3.out'
      }),
      gsap.from('nav ul li a', {
        duration: 0.8,
        opacity: 0,
        stagger: 0.2,
        delay: 1.2,
        y: -35,
        ease: 'power3.out'
      })
    }
  }
}


</script>
